import React from "react";

import Contact from "./Contact";
import OffcanvasExample from "../../Main/NavBar";
import Footer from "../../Main/Footer";
// import MapSection from "./MapSection";



const ContactPage = ({ toggleDarkMode, toggleDarkTheme }) => {
    return (

        <>
            <OffcanvasExample toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
            <Contact toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
            {/* <MapSection toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} /> */}
            <Footer toggleDarkMode={toggleDarkMode} toggleDarkTheme={toggleDarkTheme} />
        </>

    );
};

export default ContactPage;
