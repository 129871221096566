import React from 'react';
import '../styles/Partners.css';
import Marquee from "react-fast-marquee";

import partner1 from "../assets/IMG_0566 6 (1).png";
import partner2 from "../assets/مجوهرات الشرق للفضه.jpg";
import partner3 from "../assets/trend.jpg";
import partner4 from "../assets/DrScent.jpg";
import partner5 from "../assets/ZaydCenter.jpg";
import partner6 from "../assets/G5.jpg";
import partner7 from "../assets/AfaqTechnology.jpg";
import partner8 from "../assets/lipoFitness.jpg";
import partner9 from "../assets/shamModa.jpg";
import partner10 from "../assets/toyStore.png";
import partner11 from "../assets/متجر الأخوة للأكترونات.jpg";
import partner12 from "../assets/lava Store.jpg";

const Partners = () => {
    const partners = [
        { image: partner1, name: 'Shredz Libya ', link: 'https://www.facebook.com/skinycareu?mibextid=LQQJ4d' },
        { image: partner2, name: 'مجوهرات الشرق للفضة', link: '  https://www.facebook.com/SharqSilver?mibextid=ZbWKwL' },
        { image: partner3, name: 'Trend', link: 'https://www.facebook.com/Trend.218.LY' },
        { image: partner4, name: 'Dr. Scent', link: 'https://www.facebook.com/share/1BXVJqq7En/?mibextid=wwXIfr' },
        { image: partner5, name: 'Zayd Center ', link: 'https://www.facebook.com/zaydcentr?mibextid=LQQJ4d' },
        { image: partner6, name: 'الجيل الخامس', link: 'https://www.facebook.com/profile.php?id=100034744433030&mibextid=ZbWKwL' },
        { image: partner7, name: 'Afaq Technology', link: 'https://m.facebook.com/profile.php?id=61559390259495&mibextid=LQQJ4d' },
        { image: partner8, name: 'Libo Fitness', link: 'https://www.facebook.com/Lipo.fitness.company?mibextid=ZbWKwL' },
        { image: partner9, name: 'Sham Moda', link: 'https://www.facebook.com/profile.php?id=61558098936448&mibextid=kFxxJD' },
        { image: partner10, name: 'Toy Store', link: 'https://thetoystore.ly/' },
        { image: partner11, name: 'متجر الأخوة للأكترونات', link: 'https://www.facebook.com/profile.php?id=100093051990852&mibextid=ZbWKwL' },
        { image: partner12, name: ' lava Store ', link: 'https://www.facebook.com/profile.php?id=61557881884159&mibextid=JRoKGi' },
    ];

    return (
        <div className="slider-container">
            <div>
                <Marquee>
                    {partners.map((partner, index) => (
                        <div key={index} className='image_wrapper'>
                            <a href={partner.link} target="_blank" rel="noopener noreferrer">
                                <img src={partner.image} alt={partner.name} />
                                <div className="image_name">{partner.name}</div>
                            </a>
                        </div>
                    ))}
                </Marquee>
            </div>
        </div>
    );
};

export default Partners;