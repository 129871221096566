import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../Main/LanguageContext';
import translations from '../../../Translations';
import '../../../styles/Contact.css';
import Lottie from 'lottie-react';
import successAnimation from '../../../assets/success.json';
import '../../../firebase';

const Contact = ({ toggleDarkMode }) => {
    const [inputvalue1, setInputvalue1] = useState('');
    const [inputvalue2, setInputvalue2] = useState('');
    const [inputvalue3, setInputvalue3] = useState('');
    const [inputvalue4, setInputvalue4] = useState('');

    const [showPopup, setShowPopup] = useState(false);

    const setDataToFirestore = async (e) => {
        e.preventDefault();
        try {
            // const timestamp = new Date().getTime();
            const date = new Date().toLocaleString();

            const timestamp = new Date();
            // const formattedDate = timestamp.toLocaleString();

            const response = await fetch('https://us-central1-libya-d369a.cloudfunctions.net/app/submitForm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: inputvalue1,
                    email: inputvalue2,
                    message: inputvalue3,
                    phone: inputvalue4,
                    // timestamp: date,
                    timestamp: timestamp,
                    date: date,
                    // timestamp: timestamp, 
                    // date: formattedDate
                }),
            });

            if (response.ok) {
                setShowPopup(true);
                setInputvalue1('');
                setInputvalue2('');
                setInputvalue3('');
                setInputvalue4('');
            } else {
                console.error('Failed to submit form');
            }
        } catch (error) {
            console.error("Error: ", error);
        }

    };


    const handleClosePopup = () => {
        setShowPopup(false);
    };


    const { language } = useContext(LanguageContext);
    const t = translations[language];

    return (
        <div className='container'>
            <h1 className="contact-title">
                {t.contactTitle}
                <span className="specialTxt">{t.contactSpecialTxt}</span>
            </h1>
            <p className="contact-paragraph">{t.contactDescription}</p>

            <div className='email_phone'>
            <div class="illustration">
    

        <div class="ilarge"></div>
        <div class="imeduim"></div>
        <div class="ismall"></div>

    </div>
            </div>

            <div className='form-map'>

                <div className='map-info'>

                    <div className='map-container'>
                        {/* <iframe
                            title="Location Map"
                            // https://g.co/kgs/C7muzHd
                            src="https://maps.app.goo.gl/cEuLSm72nNq9KDWZ8"
                            // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.8354345093747!2d144.9537353153166!3d-37.816279742021665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577d6a32f8e9c4!2sFederation%20Square!5e0!3m2!1sen!2sus!4v1622549400000!5m2!1sen!2sus"
                           
                          
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe> */}

                            <iframe 
                            title="Location Map"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d419.01589489085035!2d13.0875538!3d32.8418847!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13a893f5904bfee7%3A0x39f1f223c6ee93b3!2z2YTZitio2YrYpyDYtNmI2Kg!5e0!3m2!1sar!2seg!4v1742677903850!5m2!1sar!2seg" 
                            width="100%"
                                                        height="400"
                                                        style={{ border: 0 }}
                            allowfullscreen="" 
                            loading="lazy" 
                            referrerpolicy="no-referrer-when-downgrade">
                                
                            </iframe>
                    </div>


                </div>

                <div className={`contact-main ${language === 'ar' ? 'arabic' : 'english'}`} id="contact">
                    <div className="contact-container">

                        <div className="contact-form">
                            <form
                                method="POST"
                                className="contact-form"
                                onSubmit={setDataToFirestore}
                                dir={t.direction}>
                                <div className="form-row">
                                    <input
                                        type="text"
                                        placeholder={t.fullNamePlaceholder}
                                        className={`form-input half-width ${toggleDarkMode ? 'bg-dark dark-contact' : ''}`}
                                        name="full_name"
                                        value={inputvalue1}
                                        onChange={(e) => setInputvalue1(e.target.value)}
                                        required
                                    />
                                    <input
                                        type="text"
                                        placeholder={t.phonePlaceholder}
                                        className={`form-input half-width ${toggleDarkMode ? 'bg-dark dark-contact' : ''}`}
                                        name="Phone_num"
                                        value={inputvalue4}
                                        onChange={(e) => setInputvalue4(e.target.value)}
                                        required
                                    />
                                </div>
                                <input
                                    type="email"
                                    placeholder={t.emailPlaceholder}
                                    className={`form-input ${toggleDarkMode ? 'bg-dark dark-contact' : ''}`}
                                    name="email"
                                    value={inputvalue2}
                                    onChange={(e) => setInputvalue2(e.target.value)}
                                    required
                                />
                                <textarea
                                    placeholder={t.messagePlaceholder}
                                    className={`form-textarea ${toggleDarkMode ? 'bg-dark dark-contact' : ''}`}
                                    name="message"
                                    value={inputvalue3}
                                    onChange={(e) => setInputvalue3(e.target.value)}
                                    required
                                ></textarea>
                                <button type="submit" className="send-btn">
                                    {t.sendButton}
                                </button>


                            </form>
                        </div>
                    </div>

                    {showPopup && (

                        <div className="popup-overlay">
                            <div className={`popup-card ${language === 'ar' ? 'arabic' : 'english'}`}>
                                <Lottie animationData={successAnimation} className="popup-icon" />
                                <p className="popup-message">{t.successMessage}</p>
                                <p className="popup-submessage">{t.successMessageTxt}</p>
                                <button className="popup-button" onClick={handleClosePopup}>{t.successMessageBtn}</button>
                            </div>
                        </div>
                    )}
                </div>


            </div>

            

        </div>

    );
};

export default Contact;
