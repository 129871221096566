
const translations = {
    ar: {
        eventTitle:" سجل الان في  ",
        eventSpecialTxt:"ورشة العمل",
        eventDescription:"",
        EventNamePlaceholder:"الاسم بالكامل",
        eventEmailPlaceholder:"البريد الالكتروني",
        eventWhatsappPlaceholder:"رقم الواتساب",
        eventStoreNamePlaceholder:" اسم المتجر",
        eventLinkPlaceholder:"رابط المتجر",

        messageSentSuccess: "لقد تم ارسال رسالتك بنجاح",

        chatInput: "اكتب هنا",

        // START ARABIC FOR NAV
        homeLink: "الرئيسية",
        aboutLink: "من نحن",
        termsLink: " الشروط و الاحكام",
        fqLink: "الاسئلة الشائعة",
        contactLink: "تواصل معنا",
        eventWorkshop:"ورشة العمل",
        chatPage:"ليبو بوت",
        // joinLink: "انضم الينا",

        // START Arabic translation for the HEADER 

        // blueText: " ليبيا شوب لتجارة الأكترونية ",
        headerSubtitle: "ليبيا شوب",
        headerTitle: ` - تغير منظور التجارة الإلكترونية بشكل جديد للتسوق بأمان و سهولة ونطاق واسع. `,
        headerParagraph: "مول الكتروني يهدف الى  توفير بيئة أمنة,ممتعة وتفاعلية تـجـمـع بـيـن الـمـتـاجـر الـمـحـلـيـة  والـمـشـتـريـن فـي لـيـبـيـا",
        joinBuyer: "حمل تطبيق إي شوب",
        joinSeller: "حمل تطبيق إي باير",
        cardText: "المتاجر المتوفرة",
        cardText1: "عدد المستخدمين",
        cardText2: "الطلبات الشهرية",
        cardText3: "كمية المنتجات",

        // start arabic translation for the FOOTER
        footerTitle: "للتواصل  بنا للحصول على أي استفسار",

        // START arabic translation for the CONTACT form
        contactTitle: `كيف يمكننا مساعدتك`,
        contactSpecialTxt: " ",
        contactDescription: `نحن مستعدون لمساعدتك في أي وقت `,
        fullNamePlaceholder: "الأسم بالكامل",
        phonePlaceholder: "رقم الهاتف",
        emailPlaceholder: "البريد الإلكتروني",
        messagePlaceholder: "أكتب رسالتك",
        sendButton: "إرسال",
        direction: "rtl",
        followUs: "تابعنا",
        // START Arabic translation for the Registered Shops Cards  

        shopsCardsTitle: "متاجر ",
        shopCardsSpecialTxt: "ليبيا شوب",
        shopsCardsTxt: "المتاجر المسجلة في ليبيا دليل مختار",
        shopsCardsTxt2: " للمؤسسات التجارية المعترف بها رسميًا في جميع أنحاء البلاد",
        // START Arabic translation for the E-Shop

        sectionOne1: ``,
        sectionOne2: `تسوق بدون تعب`,
        paraSectionOne: `يمكنك الآن الشراء من  متاجر موثقـة و منتجات مـتـعـدده وتـجـربـة فـريـدة وسلسـة لتلبـيـة جـمـيع إحتياجتك المختلفة من شاشتك الى باب منزلك`,

        sectionFour1: "",
        sectionFour2: "أطلق العنان لتجارتك",
        paraSectionFour: `بـدون مـصـاريـف بـاهـظـة او نـسـبة عـلى مـبيعاتـك, سجل متجرك الأن بـكل سهـولة وكن احـد شـركاء النجاح وسيتوفر لك نظام متكامل لأدارة حسابك مـع فـتـرة أشـتـراك مـجـانـيـة تـصـل الـى 14 يـوم`,
        shopSpecial: "",
        shopTxt: `متابعة طلباتك بدقة`,
        paraShop: `تجربة التسوق الإلكتروني الناجحة تحتاج إلى الأدوات والأنظمة اللازمة لتوضيح إجراءات الطلب التي تـبدأ مـن شـاشـة هـاتـفـك حـتـى الـتـوصـيـل إلـى مـنـزلـك`,

        // START Arabic translation for the E-Buyer
        buyerSpecial: "",
        buyerTxt: "تواصل مباشر مع المتاجر",
        paraBuyer: `عـن طـريـق نـظـام الـمـراسـلـة داخـل الـتـطـبـيـق يـمـكـنك التواصل بشكل مباشر مع المتاجر المدرجة فيه وذلك لاخـد مـعـلـومـات اضـافـيـة عـن منتجاتهـم أو الاستفادة مـن عـروضـهـم او حـتـى أبـداء أرائـك حـول خـدمـاتـهـم`,
        // START Arabic translation for the Join Team
        joinTitle: "إنضم لفريقنا",
        joinSubTitle1: `هل تبحث عن فرص مستقبلية للمهن  لتطلق إبداعك، وتحقق تأثير إيجابي حقيقي على العالم؟ انضم إلى فريقنا المتميز!`,
        joinBtn: "انضم لفريقنا",
        // START Arabic translation for the Mission
        missionCardTitle: 'مهمتنا',
        missionCardSpecial: '',
        missionCardTxt: 'ربط التجار الليبيين مع المشترين من كافة أنحاء ليبيا عبر منصة إلكترونية موثوقة وسهلة الاستخدام، وتقديم تجربة تسوق مميزة تلبي احتياجاتهم',
        missionCards: [
            {
                title: 'القيم',
                description: `الــعـمـيـل قـبـل كـل شـي ,لذلك نـلـتـزم بـتـلبـية احتياجات عملائنا والأســـتـــمــاع الــيــهــم وتــوفــيــر
منـتج سلس ومبتـكر يـلـبــي كـل أحـتـيـاجـاتـهـم`
            },
            {
                title: 'الرؤية',
                description: ` الارتـــقـــاء بــالــتــجــارة الالـكـتـرونـيـة المحلية إلى مستويات المنافسة الـعـالـمـيـة وصـنـاعـة مـنصـة تجارة رقـمـيـة تـقـارع الـمـنـصـات الدولية`,
            },
            {
                title: 'الدافع',
                description: `نــهــدف إلــى أن نــكـون مـنـصـة الـتـجــارة الإلكترونية الأولى في لـيـبـيـا، حـيـث نـربـط الــشـركــات الـلـيـبـيـة بـالـمـشـترين من أجل إقــتــصــاد رقــمــي مــزدهــر`,
                // description: `الارتقاء بالسوق الوطني التجاري الى مستويات تتحدي المنصات العالمية الكبري وتكوين سوق مبتكر ونشط لتلبية كل مايحتاجه المواطن وللمتاجر والشركات المحلية لتسويق منتجاتهم بسهولة `,
            }
        ],
        // START Arabic translation for the Chat AI
        chatTitle: `الدردشة مع الذكاء الاصطناعي `,
        chatSubTitle: "للإجابة عن اسئلتك عبر الشات",
        chatText: `إذا كان لديك الكثير من الأسئلة حول خدماتنا أو أي شيء يتعلق بليبيا شوب، فلا تتردد`,
        chatTxtSub1: "",
        chatTxtSub2: "",
        sendBtn: "إرسال",
        // START Arabic translation for the Reviews Cards
        reviewTitle: "آراء المشتركين ",
        reviewTitleSpecial: "مـاذا يـقـول زبائننا",

        // START Arabic translation for the Package Pricing Cards
        pricingCardTitle: "الباقات ",
        // pricingCardSpecial:"ليبيا شوب ",
        pricingCardTxt: `ابدأ البيع عبر الإنترنت للمتاجر التي ترغب في إنشاء متجر إلكتروني`,

        subscribe: "إبدأ الاشتراك",
        // featuresTitle: "المميزات",
        bronzePackage: {
            title: "الباقة البرونزية",
            number: "10 منتجات ",
            price: "45 دل",
            monthly: "/ شهريًا",
            features: [
                "نظام الإدخال وعرض منتجات متجرك",
                "نظام لتتبع الطلبات قبل و بعد البيع",
                "نظام الرسائل الخاص لأستقبال الطلبات",
                "نظام لتتبع قائمة منتجاتك المفضلة",
                "نظام لتتبع قائمة المفضلات لمتجرك",
                "نظام لتتبع عدد الزيارات لمتجرك",
                "عمولة 0% علي قيمة المبيعات",


                "دعم فني 24 ساعة عبر التطبيق المتجر",
                "امكانية اضافة ريلز",
                "فريق مخصص للتدريب وتنزيل منتجاتك",

            ]
        },
        silverPackage: {
            title: "الباقة الفضية",
            number: "40 منتج ",
            price: "119 دل",
            monthly: "/ شهريًا",
            features: [
                "نظام الإدخال وعرض منتجات متجرك",
                "نظام لتتبع الطلبات قبل و بعد البيع",
                "نظام الرسائل الخاص لأستقبال الطلبات",
                "نظام لتتبع قائمة منتجاتك المفضلة",
                "نظام لتتبع قائمة المفضلات لمتجرك",
                "نظام لتتبع عدد الزيارات لمتجرك",
                "عمولة 0% علي قيمة المبيعات",


                "دعم فني 24 ساعة عبر التطبيق المتجر",
                "امكانية اضافة 5 ريلز",
                "فريق مخصص للتدريب وتنزيل منتجاتك",


            ]
        },
        goldPackage: {
            title: "الباقة الذهبية",
            number: "120 منتج ",
            price: "179 دل",
            monthly: "/ شهريًا",
            features: [
                "نظام الإدخال وعرض منتجات متجرك",
                "نظام لتتبع الطلبات قبل و بعد البيع",
                "نظام الرسائل الخاص لأستقبال الطلبات",
                "نظام لتتبع قائمة منتجاتك المفضلة",
                "نظام لتتبع قائمة المفضلات لمتجرك",
                "نظام لتتبع عدد الزيارات لمتجرك",
                "عمولة 0% علي قيمة المبيعات",
                "دعم فني 24 ساعة عبر التطبيق المتجر",
                "امكانية اضافة 10 ريلز ",
                "فريق مخصص للتدريب وتنزيل منتجاتك",


            ]
        },

    platinumPackage: {
    title: " باقة برايم",
    number: "130 منتج",
    price: "1790 دل",
    monthly: "/ سنويًا",
    features: [
        "نظام الإدخال وعرض منتجات متجرك",
        "نظام لتتبع الطلبات قبل و بعد البيع",
        "نظام الرسائل الخاص لأستقبال الطلبات",
        "نظام لتتبع قائمة منتجاتك المفضلة",
        "نظام لتتبع قائمة المفضلات لمتجرك",
        "نظام لتتبع عدد الزيارات لمتجرك",
        "عمولة 0% علي قيمة المبيعات",
        "دعم فني 24 ساعة عبر التطبيق المتجر",
        "امكانية اضافة 100 ريلز ",
        "فريق مخصص للتدريب وتنزيل منتجاتك",

    ]  },
  diamondPackage: {
    title: " باقة بلس",
    number: "44 منتج",
    price: "1190 دل",
    monthly: "/ سنويًا",
    features: [
        "نظام الإدخال وعرض منتجات متجرك",
        "نظام لتتبع الطلبات قبل و بعد البيع",
        "نظام الرسائل الخاص لأستقبال الطلبات",
        "نظام لتتبع قائمة منتجاتك المفضلة",
        "نظام لتتبع قائمة المفضلات لمتجرك",
        "نظام لتتبع عدد الزيارات لمتجرك",
        "عمولة 0% علي قيمة المبيعات",
        "دعم فني 24 ساعة عبر التطبيق المتجر",
        "امكانية اضافة 50 ريلز ",
        "فريق مخصص للتدريب وتنزيل منتجاتك",
    ]  },
  pearlPackage: {
    title: "باقة برو ",
    number: "12 منتج",
    price: "450 دل",
    monthly: "/ سنويًا",
    features: [
        "نظام الإدخال وعرض منتجات متجرك",
        "نظام لتتبع الطلبات قبل و بعد البيع",
        "نظام الرسائل الخاص لأستقبال الطلبات",
        "نظام لتتبع قائمة منتجاتك المفضلة",
        "نظام لتتبع قائمة المفضلات لمتجرك",
        "نظام لتتبع عدد الزيارات لمتجرك",
        "عمولة 0% علي قيمة المبيعات",
        "دعم فني 24 ساعة عبر التطبيق المتجر",
        "امكانية اضافة 25 ريلز ",
        "فريق مخصص للتدريب وتنزيل منتجاتك",
        
    ]  },

        // START Arabic translation for the FAQ

        faq: {
            faqTitle: "الأسئلة الأكثر شيوعاً ",
            faqSpecial: "إجابات على الأسئلة الأكثر شيوعا.",
            // howManyApps: {
            //     question: "كم عدد التطبيقات المتاحة في ليبيا شوب ؟",
            //     answer: [
            //         "تطبيق التسوق الذي يسمح للمشترين بشراء المنتجات، تتبع الطلبات، والتواصل مع المتاجر",
            //         "تطبيق يُخصص للمتاجر لرفع المنتجات، التسويق لها، وبيعها، بالإضافة إلى وجود مركز التحكم",
            //         "كل من التطبيقين متصلين بنفس خوادم السحابية حيث يتم تزامن البيانات تلقائياً"
            //     ]
            // },
            paymentMethodsStore: {
                question: "ماهي طرق الدفع المتوفرة لإنشاء متجر إلكتروني ؟",
                answer: "كاش نقدي, سداد, إدفعلي, كروت البنكية, موبي ناب"
            },
            marketplace: {
                question: "ماهي منصة ليبيا شوب ماركت بليس ؟",
                answer: "سوق متعدد البائعين يسمح للمتاجر بالتسجيل وبيع المنتجات، بالإضافة إلى إتاحة الفرصة للمشترين لشراء مجموعة واسعة من المنتجات عبر الإنترنت، مع توفر مزايا ووسائل دفع متعددة ."
            },
            subscribeBuyer: {
                question: "كيف يمكنني الإشتراك كمشتري ؟",
                answer: [
                    "تحميل التطبيق: ابحث عن التطبيق على متجر أبل أو نظام أندرويد قوقل بلي تحت اسم ليبياشوب",
                    "تحميل تطبيق الويب علي libyashop.app"
                ]
            },
            // subscribeStore: {
            //     question: "كيف يمكنني الإشتراك كمتجر ؟",
            //     answer: `
            //         حمل التطبيق Libyashop: ابحث عن التطبيق على متجر أبل أو نظام  أندرويد فوقل بلي تحت اسم 

            //         تحميل تطبيق الويب علي eshop.libyashop.app
            //         `
            // },
            paymentMethodsPurchase: {
                question: "ماهي طرق الدفع المتاحة للشراء ؟",
                answer: "كل متجر يمتلك منتجات يقوم بتحديد وسائل الدفع المتاحة لكل منتج، كما هو موضح في التطبيق"
            },
            // freeTrial: {
            //     question: "هل يوجد فترة تجريبية مجانية للمتاجر ؟",
            //     answer: "نعم أسبوعين لي جميع الباقات وبعد ذلك يتطلب الأشتراك الشهري حسب الباقة"
            // }
        },

        // START Arabic translation for the TERMS AND CONDITIONS
        termsTitle: "الشروط و الاحكام",
        conditions: [
            {
                id: 1,
                que: "خصوصية المعلومات",
                term: `يحق للمستهلك إعادة أي منتج قام بشرائه خلال 72 ساعة، واسترداد قيمته المالية، وذلك إذا كان به أي عيب صناعي، أو كان مناقضًا للمواصفات المرفقة مع المنتج.
              
              في هذه الحالة يلتزم البائع برد قيمة المنتج، أو استبدالها دون أي شرط.
              
              يجب على العميل الاحتفاظ بفاتورة الشراء لضمان حقوقه.
              
              لا يتم الاسترجاع أو الاستبدال إذا كانت طبيعة المنتج أو خصائصه أو طريقة تعبئته أو تغليفه تحول دون استبداله أو رده، أو يستحيل معه إعادته إلى الحالة التي كان عليها عند الشراء.
              
              لا يتم استرجاع أو استبدال المنتجات الاستهلاكية القابلة للتلف السريع.
              
              لا يتم الاسترجاع أو الاستبدال إذا لم يكن المنتج بذات الحالة التي كان عليها وقت البيع لسبب يرجع إلى المستهلك.
              
              لا يتم الاسترجاع أو الاستبدال للمنتجات إذا كانت من المنتجات التي تصنع بناءً على مواصفات خاصة حددها المستهلك، وكان المنتج مطابق لهذه المواصفات.
              
              لا يتم استرجاع أو استبدال الكتب والصحف والمجلات، والبرامج المعلوماتية وما يماثلها أو إذا كان المنتج يعد من الحلي والمجوهرات وما في حكمها، والملابس الداخلية وفساتين الزفاف والأغراض الشخصية.`
            },
            {
                id: 2,
                que: "المصداقية والالتزام",
                term: `قبل البيع: يرجى التأكد من أن جميع المنتجات المعروضة في متجرك على منصة ليبيا شوب دقيقة ومطابقة للمنتجات الفعلية المتاحة. هذا لمنع النزاعات بعد البيع، وضمان عمليات بيع سلسة، والحفاظ على بيئة تجارة إلكترونية آمنة وشفافة وشرعية.
              
              بعد البيع: عند تلقي الطلبات من المشترين، لديك السيطرة الكاملة لمراجعتها أو قبولها أو رفضها. يمكنك تحديد مواعيد التسليم، وتحديث حالات التسليم، وتأكيد استلام الدفع للطلبات.
              
              ضبط تفاصيل الطلب بعناية لأنها تتزامن على الفور مع تطبيق المشتري. يتم تفصيل كل طلب، مما يتيح لك مراقبة حالة كل منتج على حدة، مما يضمن الدقة والشفافية والتتبع المناسب للطلبات بعد البيع.`
            },
            {
                id: 3,
                que: "الاشتراك الشهري",
                term: `استمتع بتجربة تسوق فريدة من خلال حزم الاشتراك الشهرية أو السنوية في تطبيق "ليبيا شوب".
              
              نقدم أربع حزم مختلفة، كل منها يأتي مع مجموعة مفصلة من الميزات الموضحة في الحزمة.
              
              مع خيارات الدفع المتعددة المتاحة، يمكنك اختيار الحزمة التي تناسبك.
              
              يرجى ملاحظة أن فترة الاشتراك هي 14 يومًا فقط. تتيح لك كل حزمة اشتراك عرض عدد معين من المنتجات المصممة لتلبية مبيعات متجرك.
              
              يوفر وسيلة سهلة للتواصل مع المشترين من خلال استقبال الرسائل والرد عليها داخل التطبيق.
              
              نحن نقدم دعمًا مخصصًا للمتاجر المسجلة ومجموعة من الفوائد الحصرية الأخرى وفقًا للاشتراك الذي تختاره.`
            },
            {
                id: 4,
                que: "الفترة التجريبية والمجانية",
                term: `استمتع بتجربة تسوق مميزة مع باقات الاشتراك الشهري في تطبيق "ليبيا شوب" إي شوب.
              
              نقدم ثلاث باقات مختلفة، كل منها تأتي مع مجموعة من المزايا مفصلة في وصف الباقة.
              
              ومع وجود خيارات متعددة للدفع، يمكنك اختيار الباقة التي تناسبك بشكل أفضل.
              
              يرجى العلم أن الفترة الزمنية للاشتراك 14 يوم فقط. تتيح لك كل باقة اشتراك عرض عدد معين من المنتجات المصممة خصيصًا لتلبية عدد مبيعات متجرك.
              
              يوفر وسيلة سهلة للتواصل مع المشترين من خلال استقبال والرد على الرسائل داخل التطبيق.
              
              نقدم دعمًا مخصصًا للمتاجر المسجلة ومجموعة من المزايا الحصرية الأخرى وفقًا للاشتراك الذي تختاره.
              
              ترقية باقة الاشتراك: إذا تطلبت احتياجات عملك التطور، فلا تقلق. يمكنك بسهولة ترقية اشتراكك للباقة ذات المستوى الأعلى، مما يمنحك فرصة عرض مجموعة أوسع من المنتجات والاستفادة من مزايا محسنة بناءً على الباقة المختارة.
              
              تنويه: يرجى ملاحظة أن الأسعار والمزايا قد تتغير مع مرور الوقت. ومع ذلك، هذه التغييرات لن تؤثر على الاشتراكات الحالية. نحن ملتزمون بتوفير شفافية ومرونة لضمان أنك دائمًا تحصل على أفضل قيمة من اشتراكك.`
            },
            {
                id: 5,
                que: "الاستبدال والاسترجاع",
                term: `سياسة الاسترجاع: يجب أن يكون طلب الاسترجاع لأسباب عدم الرضا عن المنتج محددة. يمكن للعميل (المشتري) طلب استرجاع المبلغ المدفوع من المتجر المسجل لدينا إذا توفرت أسباب محددة وهي أن مواصفات المنتج المعروض (فقط في تطبيق ليبيا شوب) لا تتطابق مع حالة أو شكل أو نوع المنتج الذي تم استلامه عن طريق خدمة التوصيل أو عند الاستلام من المتجر المسجل لدينا.
              
              سياسة استرجاع النقود: يمكن استرجاع المبلغ المدفوع إلى العميل فقط بنفس طريقة الدفع التي تم بها الشراء (على سبيل المثال، بطاقة الائتمان، نقدًا، أو إحدى طرق الدفع المتاحة عند إتمام عملية الشراء).
              
              سياسة الاستبدال: المنتجات المعيبة: إذا كان المنتج معيبًا أو غير مطابق للوصف، يمكن للعميل استبداله إن أراد خلال فترة الاستبدال المحددة من قبل المتجر المسجل لدينا. يجب إرفاق رقم طلب الشراء والتواصل بالمراسلة مع الدعم الفني عبر التطبيق لتقديم طلب الاستبدال.
              
              الاستبدال بدون سبب محدد وارد، ولكن يجب موافقة المتجر المالك للمنتج عبر تطبيق ليبيا شوب.`
            },
            {
                id: 6,
                que: "تتبع الطلب والتوصيل",
                term: `خاص بالمشتري:
              
              نحن في ليبيا شوب نلتزم بتوفير تجربة تسوق مميزة ومريحة لعملائنا الكرام. نفهم أهمية معرفة مكان وحالة طلبك الإلكتروني، ولذلك نقدم لكم خدمة تتبع الطلب الإلكتروني من خلال تطبيق ليبيا شوب على الهواتف النقالة.
              
              كيف يمكنكم تتبع طلبكم:
              
              1. متابعة الطلب عبر التطبيق: بمجرد تسجيل الدخول إلى تطبيق ليبيا شوب على هاتفك النقال، ستجدون قسمًا خاصًا بالطلبات الخاصة بكم في قسم حسابي.
              
              2. اختيار الطلب: انتقوا الطلب الذي ترغبون في تتبعه من القائمة.
              
              3. معلومات الطلب: ستجدون تفاصيل مفصلة حول طلبكم، بما في ذلك قائمة بالمنتجات المطلوبة وكمياتها وسعرها وجميع المواصفات الفنية.
              
              4. مكان الطلب: ستظهر لكم معلومات حية حول موقع طلبكم على الخريطة، بما في ذلك العنوان والوقت المتوقع للتسليم.
              
              5. تحديثات مباشرة: نقدم لكم تحديثات مباشرة حول حالة طلبكم، بما في ذلك تأكيد الطلب وتحضير الشحنة ومرحلة التوصيل.
              
              6. التواصل مع المتجر: يمكنكم أيضًا التواصل مباشرة مع المتجر في تطبيق ليبيا شوب في قسم الرسائل إذا كنتم بحاجة إلى تعديل في موقع التسليم أو لأي استفسار آخر.
              
              أمان وسرية البيانات:
              
              نؤكد على أن سرية وأمان معلوماتكم الشخصية وتفاصيل طلبكم تأتي في المقام الأول. نحن نستخدم أحدث التقنيات لحماية بياناتكم ونقوم بمشاركتها فقط مع الأشخاص ذوي الصلة بمعالجة وتوصيل الطلبات.
              
              نحن دائمًا هنا لمساعدتكم وتلبية احتياجاتكم أثناء تجربة التسوق معنا. إذا كان لديكم أي استفسارات أو استفسارات إضافية حول خدمة تتبع الطلب، فلا تترددوا في الاتصال بفريق خدمة العملاء لدينا.
              
              شكرًا لاختياركم للتسوق مع ليبيا شوب على الهواتف النقالة. نتمنى لكم تجربة تسوق ممتعة وراضية.`
            },
            {
                id: 7,
                que: "الدفع الإلكتروني والنقدي",
                term: `نحن نهتم بتوفير خيارات دفع متنوعة لجعل تجربة التسوق عبر التطبيق سهلة ومريحة. نقدم عدة خيارات لعملائنا للدفع: الدفع الإلكتروني والدفع النقدي.
              
              الدفع الإلكتروني:
              
              نحن نقدم وسائل دفع إلكترونية متعددة، آمنة وموثوقة لتلبية احتياجاتكم. يمكنكم الدفع الإلكتروني عن طريق الوسائل المتاحة لدينا ولدى المتاجر المسجلة في تطبيق ليبيا شوب.
              
              يرجى العلم بأن وسيلة الدفع المتاحة في قسم مواصفات ومعلومات المنتج مطابقة لطريقة الدفع عند إتمام عملية الشراء. على سبيل المثال، إذا كانت وسيلة الدفع المتاحة للمنتج نقدًا فقط، فلا يمكن استخدام إلا تلك الوسيلة، وإن كان يدعم سداد أو ادفعلي، فذلك سيكون متاحًا أيضًا.
              
              يتم معالجة معلومات الدفع الإلكتروني الخاصة بكم بسرية تامة، ونستخدم تقنيات تشفير متقدمة لضمان أمان العمليات المالية ولا نقوم باستخدام معلومات الدفع الخاصة بك نهائيًا.
              
              الدفع النقدي:
              
              يمكنكم اختيار الدفع نقدًا عند الاستلام، حسب الخيارات المتاحة في المتجر الذي اخترتموه.
              
              سيتم إرسال طلب الشراء إلى المتجر وتحديد آلية الدفع المتاحة لديكم.`
            }
        ],


        // START ARABIC translation for the FOOTER
        footerLicence: ` ترخيص رقم 4073371`,
        supportTxt: `شركة مصممون المستقبل`,
        phoneNum: "091-141-7171",
        otherTxt: "طرابلس، ليبيا",
        ourCompany: `شركتنا`,
        copyRight: `2025 © جميع الحقوق محفوظة | منصة ليبيا شوب`,
        menus: 'القوائم',
        main: 'الرئيسية',
        about: 'معلومات عنا',
        contact: 'تواصل معنا',
        otherLinks: 'روابط أخرى',
        faqTitle: 'الأسئلة الشائعة',
        privacyPolicy: ' الشروط و الاحكام',
        socialMedia: 'التواصل الاجتماعي',
        facebook: "فيسبوك",
        linkedIn: "لينكدان",
        tikTok: "تيك توك",
        insta: "انستجرام",
        textAlign: 'right',




        paymentTitle: "ادفع بالطريقة المفضلة",
        paymentTxt: "سواء كنت مشتري أو متجر ألكتروني يمكنك الأن الدفع بجميع الطرق المتاحة فى ليبيا بكل بساطة, سرعة وأمان تام",

        languageToggle: "English",
        successMessage:"لقد تم ارسال طلبك بنجاح",
        successMessageTxt:"سيقوم فريق الدعم الفني بالتواصل معك في اقرب وقت",
        successMessageBtn:"متابعة",

        switchToYearly:"سنويًا",
        switchToMonthly:"شهريًا",

        monthlyTitle:"شهريًا",
        yearlyTitle:"سنويًا",



          // Video Section
          videoTxt: `
          سجِّل متجرك وابدأ البيع إلكترونيًا باستخدام جميع وسائل الدفع الإلكتروني والنقدي، مع خدمة التوصيل إلى جميع أنحاء ليبيا بكل سهولة. استمتع بمنصة متكاملة تمنحك تجربة استثنائية، دون قيود جغرافية أو التنازل عن علامتك التجارية، مع تحكم كامل في إدارة تجارتك.`,
          videoBtn: '',
          videoBtn2: '',

        paymentMethods: "طرق الدفع",
        method1: "",
        method2: "",
        method3: "",
        method4: "",
        method5: "",
        method6: "",
        total: "المجموع",
        purchaseSubscription: "تأكيد المعاملة",
        agreeTerms: "بالشراء ، ستوافق على",
        termsConditions: "الشروط والأحكام",


        paymentSuccess:" 🎉 تم الدفع بنجاح",
        successTxt:"تمت عملية الدفع الخاصة بك بنجاح ، يمكنك التواصل معنا في أي وقت لمزيد من التفاصيل",
        goHome:"إذهب للرئيسية",
    },


    en: {


        eventTitle:"Register for ",
        eventSpecialTxt:"The Workshop",
        eventDescription:"",
        EventNamePlaceholder:"Full Name",
        eventEmailPlaceholder:"Email",
        eventWhatsappPlaceholder:"Whatsapp",
        eventStoreNamePlaceholder:"Store Name",
        eventLinkPlaceholder:"Store Link",




        paymentSuccess:"Payment Success! 🎉",
        successTxt:"Your payment was completed successfully.",
        goHome:"Go Home",

        paymentMethods: "Payment Methods",
        method1: "Bank Mastercard or Visa",
        method2: "Saddad",
        method3: "ُEdfa3ly",
        method4: "Tadawul",
        method5: "Paypal",
        method6: "Mobi Cash",
        total: "Total",
        purchaseSubscription: "Purchase Subscription",
        agreeTerms: "By purchasing you will agree to our",
        termsConditions: "terms and conditions",

        // messageSentSuccess: "Your message has been sent successfully.",
        successMessage:"Your message has been sent successfully.",
        successMessageTxt:"The technical support team will contact you as soon as possible.",
        successMessageBtn:"Continue",

        chatInput: "Write here",

        // START ENGLISH FOR NAV
        homeLink: "Home",
        pricingLink: "Pricing",
        termsLink: "Terms & Conditions",
        contactLink: "Contact Us",
        aboutLink: "About Us",
        fqLink: "F&Q",
        joinLink: "Join Us",
        eventWorkshop: "Event Workshop",
        chatPage:"Libobot",

    
            mapTitle: "Our Location",
            mapDescription: "Visit us at our convenient location in the heart of the city.",
            mapAddress: "123 Main St, City, Country",
            mapPhone: "+123 456 7890",
            mapEmail: "info@example.com",
    

        // START english translation for the HEADER 
        blueText: "LibyaShop for Electronic Commerce",
        headerSubtitle: "LibyaShop ",

        headerTitle: " - Changing the perspective of e-commerce with a new way of safe shopping.",
        headerParagraph: "An online mall aimed at integrating all local stores and buyers in Libya on one safe and beneficial platform.",
        joinBuyer: "Download E-Shop App",
        joinSeller: "Download E-Buyer App",
        cardText: "Available Stores",
        cardText1: "Number of Users",
        cardText2: "Monthly Orders",
        cardText3: "Number of Products",



        // Video Section
        videoTxt: `
        Register your store, sell online with all payment options, and enjoy easy delivery across Libya. Manage your business freely with no limits.
        `,
        videoBtn: 'E-Shop App',
        videoBtn2: '',
        // REVIEWS
        reviewTitle: "The Reviews",
        reviewTitleSpecial: "What Our Customers Say",


        // shop cards
        shopsCardsTitle: "Libya's Registered ",
        shopCardsSpecialTxt: "shops",
        shopsCardsTxt: "Libya's Registered Shops A curated directory of officially ",
        shopsCardsTxt2: "recognized retail establishments across the nation.",

        // START english translation for the CONTACT form
        contactTitle: "How Can We Help You?",
        contactSpecialTxt: "",
        contactDescription: "We are always ready to help you any time",
        fullNamePlaceholder: "Full Name",
        phonePlaceholder: "Phone Number",
        emailPlaceholder: "Email",
        messagePlaceholder: "Write Your Message",
        sendButton: "Send",
        direction: "ltr",
        followUs: "Follow Us",


    
        paymentTitle: "Pay with best way",
        paymentTxt: "Whether you are a buyer or an online store, you can now pay using all available methods in Libya easily, quickly, and securely.",

        // START English translation for the Mission
        missionCardTitle: "Our Mission",
        missionCardSpecial: "",
        missionCardTxt: "Connecting Libyan merchants with buyers from all over Libya through a reliable and easy-to-use online platform, providing a unique shopping experience that meets their needs.",
        missionCards: [
            {
                title: "Value",
                description: "Elevating local e-commerce to global competition levels and creating a digital trading platform that rivals international platforms."
            },
            {
                title: "Vision",
                description: "Elevating local e-commerce to global competition levels and creating a digital trading platform that rivals international platforms."
            },
            {
                title: "MTP",
                description: "Our aim is to be the leading e-commerce platform in Libya, connecting Libyan companies with buyers for a thriving digital economy."
            }
        ],
        // START ENGLISH translation for the E-Shop
        sectionOne1: ``,
        sectionOne2: "Shop Without Hassle",
        paraSectionOne: "You can now buy from verified shops with various products and a unique, smooth experience to meet all your different needs from your screen to your doorstep.",

        sectionFour1: "",
        sectionFour2: "Unleash Your Business",
        paraSectionFour: `Without high costs or sales commissions, you can easily register your store now and become one of our success partners. You'll have access to a complete account management system with a free subscription period of up to 14 days.`,
        shopSpecial: "",
        shopTxt: "Track Your Orders Accurately",
        paraShop: "A successful online shopping experience requires the necessary tools and systems to clarify order procedures that start from your phone screen until delivery to your home.",

        // START English translation for the E-Buyer
        buyerSpecial: "",
        buyerTxt: "Direct Communication with Stores",
        paraBuyer: `You can directly communicate with the listed stores through the in-app messaging system to get additional information about their products, benefit from their offers, or even share your feedback about their services.`,
        // start english translation for the pricing cards
        pricingCardTitle: "Packages ",
        // pricingCardSpecial: "packages",
        pricingCardTxt: "Start selling online to stores who want to create an online store in Market Place",
        subscribe: "Subscribe",
        featuresTitle: "Features",
        bronzePackage: {
            title: "Bronze Package",
            number: "10 products",
            price: "45LYD",
            monthly: "/ month",
            features: [
                "Product entry and display system",
                "Order tracking system before & after sales",
                "Private messaging system to receive orders",
                "System to track your favorite products list",
                "System to track your store's favorite list",
                "System to track the number of visits to your store",
                "0% Commission on sales value",
                "24/7 technical support via the store app",
                "Add your own Reels ",
                "Team for training & uploading products"
            ]
        },
        silverPackage: {
            title: "Silver Package",
            number: "40 products",
            price: "119LYD",
            monthly: "/ month",
            features: [
                "Product entry and display system",
                "Order tracking system before & after sales",
                "Private messaging system to receive orders",
                "System to track your favorite products list",
                "System to track your store's favorite list",
                "System to track the number of visits to your store",
                "0% Commission on sales value",
                "24/7 technical support via the store app",
                "Add 5 Reels ",
                "Team for training & uploading products"
            ]
        },
        goldPackage: {
            title: "Gold Package",
            number: "120 products",
            price: "179LYD",
            monthly: "/ month",
            features: [
                "Product entry and display system",
                "Order tracking system before & after sales",
                "Private messaging system to receive orders",
                "System to track your favorite products list",
                "System to track your store's favorite list",
                "System to track the number of visits to your store",
                "0% Commission on sales value",
                "24/7 technical support via the store app",
                "Add 10 Reels ",
                "Team for training & uploading products"
            ]
        },
        platinumPackage: {
            title: "Prime Package",
            number: "130 products",
            price: "1790LYD",
            monthly: "/ yearly",
            features: [
                "Product entry and display system",
                "Order tracking system before & after sales",
                "Private messaging system to receive orders",
                "System to track your favorite products list",
                "System to track your store's favorite list",
                "System to track the number of visits to your store",
                "0% Commission on sales value",
                "24/7 technical support via the store app",
                "Add 100 Reels ",
                "Team for training & uploading products"
            ]
        },
          diamondPackage: {
            title: " Plus Package",
            number: "44 products",
            price: "1190LYD",
            monthly: "/ yearly",
            features: [
          
                "Product entry and display system",
                "Order tracking system before & after sales",
                "Private messaging system to receive orders",
                "System to track your favorite products list",
                "System to track your store's favorite list",
                "System to track the number of visits to your store",
                "0% Commission on sales value",
                "24/7 technical support via the store app",
                "Add 50 Reels ",
                "Team for training & uploading products"
            ]
          },
          pearlPackage: {
            title: " Pro Package",
            number: "12 products",
            price: "450LYD",
            monthly: "/ yearly",
            features: [
                "Product entry and display system",
                "Order tracking system before & after sales",
                "Private messaging system to receive orders",
                "System to track your favorite products list",
                "System to track your store's favorite list",
                "System to track the number of visits to your store",
                "0% Commission on sales value",
                "24/7 technical support via the store app",
                "Add 25 Reels ",
                "Team for training & uploading products"
            ]
          },

          monthlyTitle:"Monthly",
          yearlyTitle:"Yearly",



        // Frequently Asked Questions

        faq: {
            faqTitle: "The Most Common Questions",
            faqSpecial: "Answers to the most common questions",
            howManyApps: {
                question: "How many applications are available on Libya Shop?",
                answer: [
                    "A shopping application that allows buyers to purchase products, track orders, and communicate with stores.",
                    "An application dedicated to stores for listing products, marketing them, and selling, in addition to having a control center.",
                    "Both applications are connected to the same cloud servers where data synchronization occurs automatically."
                ]
            },
            paymentMethodsStore: {
                question: "What are the payment methods available for creating an online store?",
                answer: "Cash, Sadad, Edfa3li, Bank Cards, MobiNab."
            },
            marketplace: {
                question: "What is the Libya Shop Marketplace?",
                answer: "A multi-vendor marketplace that allows stores to register and sell products, in addition to providing buyers with the opportunity to purchase a wide range of products online, with multiple advantages and payment methods available."
            },
            subscribeBuyer: {
                question: "How can I subscribe as a buyer?",
                answer: [
                    "Download the app: search for the app on the Apple Store or Google Play Store under the name Libya Shop.",
                    "Download the web application at libyashop.app."
                ]
            },
            subscribeStore: {
                question: "How can I subscribe as a store?",
                answer: ""
            },
            paymentMethodsPurchase: {
                question: "What are the payment methods available for purchasing?",
                answer: "Each store that has products determines the available payment methods for each product, as shown in the app."
            },
            freeTrial: {
                question: "Is there a free trial period for stores?",
                answer: "Yes, two weeks for all packages, after which a monthly subscription is required based on the package."
            }
        },


        // START English translation for the TERMS AND CONDITIONS
        termsTitle: "Terms and Conditions",
        conditions: [
            {
                id: 1,
                que: "Privacy Information",
                term: `ustomers have the right to return any purchased product within 72 hours if it has any manufacturing defects or does not meet the specified standards.
                
                In such cases, the seller must refund the product's value or replace it unconditionally.
                
                Customers must keep the purchase receipt to safeguard their rights. 
                Products cannot be returned or exchanged if their nature, properties, packaging, or condition prevents their resale or return to their original state. 
                Perishable goods are non-returnable or non-exchangeable. Products cannot be returned or exchanged if they do not match the condition they were sold in due to customer-induced reasons. 
                
                Customized products specified by the customer and meeting those specifications are not eligible for return or exchange. Books, newspapers, magazines, software, jewelry, intimate 
                
                apparel, wedding dresses, and personal items are non-returnable or non-exchangeable.`
            },
            {
                id: 2,
                que: 'Credibility and Commitment',
                term: `Pre-Sale: Please ensure that all products displayed in your store on the Libya Shop platform are accurate and identical to the actual available products. 
                This is to prevent conflicts after sales, ensure smooth sales operations, and maintain a safe, transparent, and legitimate e-commerce environment. 
                Post-Sale: Upon receiving orders from buyers, you have full control to review, accept, or reject them. 
                You can set delivery dates, update delivery statuses, and confirm payment receipt for orders. Carefully adjust order details as they are instantly synced with the buyer's application. 
                Each order is detailed, allowing you to monitor each product's status individually, ensuring accuracy, transparency, and proper post-sale order tracking.`
            },
            {
                id: 3,
                que: 'Monthly Subscription',
                term: `Enjoy a unique shopping experience with monthly or annual subscription packages in the 'Libya Shop' app. 
                We offer four different packages, each of which comes with a detailed set of features described in the package. 
                With multiple payment options available, you can choose the package that best suits you. Please note that the subscription period is only 14 days. 
                Each subscription package allows you to showcase a specific number of products designed to meet your store's sales. 
                It provides an easy way to communicate with buyers by receiving and responding to messages within the app. 
                We offer dedicated support for registered stores and a range of other exclusive benefits according to the subscription you choose.`
            },
            {
                id: 4,
                que: 'Subscription Upgrade',
                term: `If your business needs evolve, don't worry. You can easily upgrade your subscription to a higher-level package, giving you the opportunity to showcase a wider range of products and benefit from enhanced features based on the selected package.

                        Notice:

                        Please note that prices and features may change over time. However, these changes will not affect current subscriptions. 
                        We are committed to providing transparency and flexibility to ensure that you always get the best value from "Libya Shop."

                        Trial and Free Period:

                        Enjoy a unique shopping experience with monthly subscription packages in the "Libya Shop" app. 
                        We offer three different packages, each of which comes with a detailed set of features described in the package. With multiple payment options available, you can choose the package that best suits you. Please note that the subscription period is only 14 days. Each subscription package allows you to showcase a specific number of products designed to meet your store's sales. It provides an easy way to communicate with buyers by receiving and responding to messages within the app. We offer dedicated support for registered stores and a range of other exclusive benefits according to the subscription you choose.
                        `
            },
            {
                id: 5,
                que: 'How can you track your order',
                term: `Order tracking through the app: 
                        Once you log in to the Libya Shop app on your mobile phone, you will find a section dedicated to your orders in the My Account section.

                        Select the order: 
                        Choose the order you wish to track from the list.

                        Order information: 
                        You will find detailed information about your order, including a list of the requested products, quantities, prices, and all technical specifications.

                        Order location: 
                        Live information about the location of your order on the map will be displayed, including the address and the expected delivery time.

                        Real-time updates: 
                        We provide you with real-time updates on the status of your order, including order confirmation, shipment preparation, and delivery stage.

                        Communicate with the store: 
                        You can also directly communicate with the store in the Libya Shop app in the Messages section if you need to make changes to the delivery 
                        location or for any other inquiries.

                        Data security and confidentiality:
                        We assure you that the confidentiality and security of your personal information and order details are our top priority. 
                        We use the latest technologies to protect your data and only share it with individuals involved in processing and delivering orders.
                        We are always here to assist you and meet your needs during your shopping experience with us. If you have any questions or additional 
                        inquiries about the order tracking service, please feel free to contact our customer service team.

                        Thank you for choosing to shop with Libya Shop on mobile phones. We wish you an enjoyable and satisfying shopping experience.
                        `
            },
            {
                id: 6,
                que: 'Cash Payment',
                term: `If you prefer to pay cash upon product delivery, we also offer this option. You can choose cash payment when completing your purchase. 
                Please ensure you have the appropriate cash amount available when the order is delivered for cash payment. 
                Please inspect the product thoroughly before handing over the payment and refer to the product specifications.

                        In All Cases:

                        We always aim to provide the best service to our customers, whether you prefer electronic payment or cash payment. If you have any additional inquiries regarding the payment policy at Libya Shop, please feel free to contact our customer service team.
                        `
            },
            {
                id: 7,
                que: 'What is Geolocation Determination?',
                term: `Geolocation determination is the process of pinpointing your device's location based on GPS signals, Wi-Fi, or cellular network. 
                This is done to enhance your shopping experience and provide customized local services.

                        We hope this policy has clarified how we use your geolocation information and your rights in this context. 
                        If you have any questions or concerns about geolocation determination, please do not hesitate to contact our customer service team.
                        `
            },
            {
                id: 8,
                que: 'Why Do We Need Your Geolocation?',
                term: `Improving the shopping experience: By knowing your location, we can provide content and products tailored to your region.

                        Product delivery: We can enhance delivery times and methods by accurately knowing your location.

                        Account security: Geolocation can be used to protect your account from unauthorized access.

                        Disabling geolocation: You can disable geolocation service on your device at any time from your device settings.

                        Maintaining your privacy: We commit not to share your geolocation information without your consent.

                        We are committed to complying with all laws and regulations related to personal data protection and geolocation determination.
                        `
            },
            {
                id: 9,
                que: 'Store Account Verification',
                term: `The verification process for the store owner or authorized person to operate the store through the Libya Shop application is an essential 
                process that takes 24 hours for careful review. Register the required information for your store, along with providing a personal ID, commercial registration, 
                or license to complete the verification process. Libya Shop platform prioritizes the safety of buyers and sellers, aiming to ensure supervision 
                of a safe e-commerce environment at its highest levels. We are dedicated to enhancing safety levels for the online shopping experience in Libya, 
                striving to provide a distinctive and secure experience.`
            },
            {
                id: 10,
                que: 'Account Deletion',
                term: `You can delete your account at any time without restrictions; however, your activities such as orders, order details, 
                or messages will not be erased. Only your account, including your store and all your information, will be deleted, 
                and it cannot be recovered. Please note that we do not keep backups of your account details in our database. If you confirm the deletion 
                of your account, it will be permanently deleted, and there will be no way to recover it. You can only create a new account if you wish to use Libya Shop platform services. 
                Please note that this information represents the terms and conditions of service.`
            }
        ],

        // START ENGLISH translation for the FOOTER
        footerLicence: `License No 4073371`,
        supportTxt: `Future Designers Company`,
        phoneNum: "091-141-7171",
        otherTxt: "Tripoli, Libya",
        ourCompany: `Our Company`,
        copyRight: "Copyright © 2025 LibyaShop. All rights reserved.",
        menus: 'Menus',
        main: 'Home',
        about: 'About Us',
        contact: 'Contact',
        otherLinks: 'Other Links',
        faqTitle: 'F&Q',
        privacyPolicy: 'Terms & Conditions',
        socialMedia: 'Social Media',
        facebook: "Facebook",
        linkedIn: "LinkedIn",
        tikTok: "TikTok",
        insta: "Instagram",
        languageToggle: "عربي",


        switchToYearly:"Yearly",
        switchToMonthly:"Monthly",

    }
}

export default translations;
